<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <b-row class="mb-0">
              <b-col cols="3" v-if="!storelist || (storelist.length==0)">
                <b-form-group
                  label="Store ID:"
                  label-for="store_id"
                  label-cols="6"
                  label-align="right"
                >
                  <b-form-input id="store_id" v-model="store_id"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <el-button
                  v-if="posking"
                  size="mini"
                  type="primary"
                  @click="get_license"
                  v-acl:StoreEdit.enable.show
                  >{{ $t("Get License") }}</el-button>
              </b-col>
              <b-col cols="2">
                <el-button
                  type="primary"
                  size="mini"
                  @click="go_shift_time"
                  v-acl:StoreEdit.enable.show
                  >{{ $t("Shift Time") }}</el-button>
              </b-col>
            </b-row>
            <div class="p-3" v-if="storelist">
              <div>{{ $t('Company') }}</div>
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="storelist"
                @row-click="row_clicked_event"
              >
                <el-table-column label="ID" prop="LocationID">
                  <template v-slot:row>
                    {{ row.LocationID }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Name')" prop="LocationName">
                </el-table-column>
                <el-table-column :label="$t('Telephone')" prop="LocationTel">
                </el-table-column>
                <el-table-column
                  :label="$t('Address')"
                  prop="LocationAddress1"
                >
                </el-table-column>
                <el-table-column :label="$t('Store Type')" prop="StoreType">
                </el-table-column>
              </el-table>
            </div>
            <div class="p-3" v-if="storelist2">
              <div>{{ $t('Store') }}</div>
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="storelist2"
                @row-click="row_clicked_event"
              >
                <el-table-column label="ID" prop="LocationID">
                  <template v-slot:row>
                    {{ row.LocationID }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Name')" prop="LocationName">
                </el-table-column>
                <el-table-column :label="$t('Telephone')" prop="LocationTel">
                </el-table-column>
                <el-table-column
                  :label="$t('Address')"
                  prop="LocationAddress1"
                >
                </el-table-column>
                <el-table-column :label="$t('Store Type')" prop="StoreType">
                </el-table-column>
              </el-table>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-row v-if="cur_license.length > 0">
                <b-col cols="4">
                  <H4>{{ $t("License") }}</H4>
                </b-col>
              </b-row>
              <b-row class="border-bottom mb-2">
                <b-col
                  cols="4"
                  v-for="license in cur_license"
                  :key="license.IDn"
                  class="small"
                >
                  {{ license.LicenseKey }} ({{ license.Device }})
                </b-col>
              </b-row>
              <b-form>
                <b-row class="border-bottom mb-2">
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        ID
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.LocationID }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          {{ $t('Store Name') }}
                        </div>
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.LocationName }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Store Type') }}
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.StoreType }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="border-bottom mb-2">
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          GST/HST
                        </div>
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.GSTNo }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        PST
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.PSTNo }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Online') }}
                      </b-col>
                      <b-col class="col-1">
                      </b-col>
                      <b-col class="col-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="cur_store.SyncOpencart"
                          id="inlineRadio1"
                          value="0"
                        />
                        <label class="form-check-label" for="inlineRadio1">{{
                          $t("No")
                        }}</label>
                      </b-col>
                      <b-col class="col-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="cur_store.SyncOpencart"
                          id="inlineRadio2"
                          value="1"
                        />
                        <label class="form-check-label" for="inlineRadio2">{{
                          $t("Yes")
                        }}</label>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                
                <b-row class="border-bottom mb-2">
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Address') }}
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.LocationAddress1 }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('City') }}
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.LocationCity }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Province') }}
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.LocationProvince }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                
                <b-row class="border-bottom mb-2">
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Country') }}
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.LocationCountry }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Post Code') }}
                      </b-col>
                      <b-col class="col-7">
                        {{ cur_store.LocactionZIP }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Website') }}
                      </b-col>
                      <b-col class="col-7">
                        <b-input
                          placeholder="Website"
                          class="form-control input_height_1_5"
                          v-model="cur_store.LocationWebSite"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="border-bottom mb-2">
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Phone') }}
                      </b-col>
                      <b-col class="col-7">
                        <b-input
                          placeholder="Phone Number"
                          class="form-control input_height_1_5"
                          v-model="cur_store.LocationTel"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Ext') }}
                      </b-col>
                      <b-col class="col-7">
                        <b-input
                          placeholder="Extension Number"
                          class="form-control input_height_1_5"
                          v-model="cur_store.LocationTelExt"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Fax') }}
                      </b-col>
                      <b-col class="col-7">
                        <b-input
                          placeholder="Fax Number"
                          class="form-control input_height_1_5"
                          v-model="cur_store.LocationFax"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="border-bottom mb-2">
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Contact') }}
                      </b-col>
                      <b-col class="col-7">
                        <b-input
                          placeholder="Contact"
                          class="form-control input_height_1_5"
                          v-model="cur_store.Contact"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Email') }}
                      </b-col>
                      <b-col class="col-7">
                        <b-input
                          placeholder="Email"
                          class="form-control input_height_1_5"
                          v-model="cur_store.ContactEmail"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        {{ $t('Inventory') }}
                      </b-col>
                      <b-col class="col-1">
                      </b-col>
                      <b-col class="col-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="cur_store.HasInvotory"
                          id="inlineRadio2"
                          value="0"
                        />
                        <label class="form-check-label" for="inlineRadio1">{{
                          $t("No")
                        }}</label>
                      </b-col>
                      <b-col class="col-2">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="cur_store.HasInvotory"
                          id="inlineRadio2"
                          value="1"
                        />
                        <label class="form-check-label" for="inlineRadio2">{{
                          $t("Yes")
                        }}</label>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                
                <b-row class="border-bottom mb-2">
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          {{ $t('Day End Time') }}
                        </div>
                      </b-col>
                      <b-col class="col-7">
                        <flat-pickr
                          v-model="cur_store.DayEndTime"
                          :config="pickr_config"
                          class="form-control input_height_1_5"
                          placeholder="Select time"
                        >
                        </flat-pickr>
                      </b-col>
                    </b-row>
                  </b-col>
                  <!-- <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          {{ $t('Price') }}
                        </div>
                      </b-col>
                      <b-col class="col-7">
                        <select
                          class="form-control input_height_1_5"
                          v-model="cur_store.LocationPrice"
                        >
                          <option></option>
                          <option>A</option>
                          <option>B</option>
                          <option>C</option>
                          <option>D</option>
                          <option>E</option>
                          <option>F</option>
                          <option>G</option>
                          <option>H</option>
                          <option>I</option>
                          <option>J</option>
                        </select>
                      </b-col>
                    </b-row>
                  </b-col> -->
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          {{ $t('POS Setting') }}
                        </div>
                      </b-col>
                      <b-col class="col-7">
                        <select
                          class="form-control input_height_1_5"
                          v-model="cur_store.POSSettingGroupID"
                        >
                          <option
                            v-for="(g, idx) in pos_setting_groups"
                            :key="idx"
                          >
                            {{ g.GroupID }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="4">
                    <b-row>
                      <b-col class="col-5 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          {{ $t('VIP Group') }}
                        </div>
                      </b-col>
                      <b-col class="col-7">
                        <select
                          class="form-control input_height_1_5"
                          v-model="cur_store.VIPGroupIDn"
                        >
                          <option value="0"></option>
                          <option
                            v-for="(g, idx) in vip_groups"
                            :key="idx"
                            :value="g.IDn"
                          >
                            {{ g.VIPGroupName }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row class="border-bottom mb-2">
                  <b-col cols="12">
                    <base-input :label="$t('Remarks')">
                      <b-input
                        placeholder="Remarks"
                        class="form-control"
                        v-model="cur_store.Remarks"
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row class="border-bottom mb-2">
                  <b-col cols="6">
                    <b-row>
                      <b-col class="col-2 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          Logo Image
                        </div>
                      </b-col>
                      <b-col class="col-10">
                        <b-form-file
                          v-model="image1"
                          :state="Boolean(image1)"
                          placeholder="Choose a image file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </b-col>
                    </b-row>
                    <b-row v-bind:style="{ paddingTop: '10px', textAlign: 'center', }">
                      <b-col :span="6">
                        <!-- <div @click="deleteImage('Logo')">
                        </div> -->
                        <b-img-lazy
                          v-if="cur_store.Logo"
                          :src="imageBase + cur_store.Logo"
                          class="rounded picimg"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row>
                      <b-col class="col-2 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          Pad Image
                        </div>
                      </b-col>
                      <b-col class="col-10">
                        <b-form-file
                          v-model="image2"
                          :state="Boolean(image2)"
                          placeholder="Choose a image file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </b-col>
                    </b-row>
                    <b-row v-bind:style="{ paddingTop: '10px', textAlign: 'center', }">
                      <b-col :span="6">
                        <!-- <div @click="deleteImage('PadImage')">
                        </div> -->
                        <b-img-lazy
                          v-if="cur_store.PadImage"
                          :src="imageBase + cur_store.PadImage"
                          class="rounded picimg"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row>
                      <b-col class="col-2 font-weight-bold text-sm-right">
                        <div class="form-control-label">
                          Kiosk Image
                        </div>
                      </b-col>
                      <b-col class="col-10">
                        <b-form-file
                          v-model="KioskImage"
                          :state="Boolean(KioskImage)"
                          placeholder="Choose a image file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </b-col>
                    </b-row>
                    <b-row v-bind:style="{ paddingTop: '10px', textAlign: 'center', }">
                      <b-col :span="6">
                        <b-img-lazy
                          v-if="cur_store.KioskImage"
                          :src="imageBase + cur_store.KioskImage"
                          class="rounded picimg"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="justify-content-lg-center mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- <b-row class="mt-4" v-if="currpage == 'add'">
        <b-col v-if="posking">
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row class="justify-content-lg-center">
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      description="Store Unique ID from license Server"
                      label-align="right"
                      label="StoreID"
                      label-for="storeid"
                    >
                      <b-form-input
                        id="storeid"
                        class="form-control"
                        placeholder="ID"
                        v-model="cur_store.LocationID"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="get_license">{{
                      $t("Get License")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row> -->

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [flatPicker.name]: flatPicker,
  },
  data() {
    return {
      currpage: "list",
      cur_license: [],
      cur_store: null,
      cur_opt: null,
      pickr_config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      new_store: {
        LocationID: "",
        ProvinceIDn: 1,
        CountryIDn: 1,
        LocationName: "",
        LocationTel: "",
        LocationTelExt: "",
        LocationFax: "",
        LocationAddress1: "",
        LocationAddress2: "",
        DayEndTime: "",
        LocationWebSite: "",
        LocationStorageDevice: "",
        Remarks: "",
        LocationCity: "",
        LocationProvince: "",
        LocationCountry: "1",
        LocactionZIP: "",
        Contact: "",
        GSTNo: "",
        PSTNo: "",
        IsHeadquarter: "",
        LocationPrice: "",
        LocationExcludeTax: "",
        LocationTax: "TAX100,TAX200",
        POSSettingGroupID: "",
        ContactEmail: "",
        Snappli: "",
        POSPADGroupID: "",
        StoreType: "DINE-IN",
        Logo: "",
        PadImage: "",
        KioskImage: "",
      },
      store_id: "",
      storelist: null,
      storelist2: null,
      countrylist: null,
      provincelist: null,
      citylist: [],
      cur_provincelist: null,
      pos_setting_groups: null,
      vip_groups: null,

      image1: null,
      image2: null,
      KioskImage: null,

      posking: null,

      pkadmin: false,

      token: "",
      user_id: 0,

    };
  },
  computed: {
    imageBase: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    deleteImage(img) {
      if (img == 'Logo') {
        this.cur_store.Logo = '';
      } else if (img == 'PadImage') {
        this.cur_store.PadImage = '';
      } else if (img == 'KioskImage') {
        this.cur_store.KioskImage = '';
      }
    },
    setinput_city(city) {
      this.cur_store.LocationCity = city;
    },
    get_store_license() {
      if (!this.posking) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", this.cur_store.LocationID);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Store/get_license",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_license = rt.data.licenses;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    go_shift_time() {
      this.$router.push("/shift_time");
    },
    get_license() {
      if (!this.posking) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (!this.storelist || (this.storelist.length==0)) {
        bodyFormData.append("LocationID", this.store_id);
      } else {
        for (var i=0; i<this.storelist.length; i++) {
          if (!this.storelist[i].IsHeadquarter) {
            // Header
            bodyFormData.append("LocationID", this.storelist[i].LocationID);
            break;
          }
        }
      }

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Store/license",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    add_store() {
      this.currpage = "add";
      this.cur_store = this.new_store;
      if (this.cur_store.LocationTax) {
        this.cur_store.LocationTax = this.cur_store.LocationTax.split(",");
      } else {
        this.cur_store.LocationTax = [];
      }
      this.changed_country();
      this.cur_opt = "add";
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, event, column);
      if (!hasUtilityAuth('StoreEdit')) return;
      this.currpage = "edit";
      this.cur_store = JSON.parse(JSON.stringify(row));
      if (this.cur_store.LocationTax) {
        this.cur_store.LocationTax = this.cur_store.LocationTax.split(",");
      } else {
        this.cur_store.LocationTax = [];
      }
      this.changed_country();
      this.get_store_license();
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_store) {
        bodyFormData.append(key, this.cur_store[key]);
      }
      if (this.image1) {
        bodyFormData.append("image1", this.image1);
      }
      if (this.image2) {
        bodyFormData.append("image2", this.image2);
      }
      if (this.KioskImage) {
        bodyFormData.append("KioskImage", this.KioskImage);
      }

      axios({
        method: "post",
        url: "/Api/Web/Store/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    find_tax_in_cur_store(v) {
      for (var i = 0; i < this.cur_store.LocationTax.length; i++) {
        if (this.cur_store.LocationTax[i] == v) {
          return true;
        }
      }
      return false;
    },
    changed_province() {
      if (
        !this.cur_store.ProvinceIDn ||
        this.cur_store.ProvinceIDn == undefined ||
        this.cur_store.ProvinceIDn <= 0
      ) {
        return;
      }

      this.cur_store.LocationProvince =
        this.provincelist[this.cur_store.ProvinceIDn].Province;
    },
    changed_country() {
      if (
        !this.cur_store.CountryIDn ||
        this.cur_store.CountryIDn == undefined ||
        this.cur_store.CountryIDn <= 0
      ) {
        this.cur_provincelist = this.provincelist;
        return;
      }

      this.cur_store.LocationCountry =
        this.countrylist[this.cur_store.CountryIDn].Country;

      this.cur_provincelist = {};
      for (var idx in this.provincelist) {
        if (this.provincelist[idx].CountryIDn == this.cur_store.CountryIDn) {
          this.cur_provincelist[idx] = this.provincelist[idx];
        }
      }
    },
    go_license() {
      this.$router.push("/license");
    },
    split_store_list() {
      if (this.storelist.length <= 1) {
        return;
      }
      var store1 = [];
      var store2 = [];
      for (var i = 0; i < this.storelist.length; i++) {
        if (!this.storelist[i].IsHeadquarter) {
          store1.push(this.storelist[i]);
        } else {
          store2.push(this.storelist[i]);
        }
      }
      this.storelist = store1;
      this.storelist2 = store2;
    },
    async get_local_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Store",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.stores;
              that.split_store_list();
              that.countrylist = rt.data.countrys;
              that.provincelist = rt.data.provinces;
              that.citylist = rt.data.cities;
              that.cur_provincelist = rt.data.cur_provinces;
              that.posking = rt.data.posking;
              that.pos_setting_groups = rt.data.pos_setting_groups;
              that.vip_groups = rt.data.vip_groups;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              if (!that.storelist) {
                that.posking = true;
              }
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_local_list();
    },
  },
  mounted() {
    let name = getStore("username");
    if (name && name != undefined && name == "PKAdmin") {
      this.pkadmin = true;
    }

    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.store_radio_div {
  padding-top: 0.5rem;
}
.input_height_1_5 {
  height: 1.5rem;
  padding: 0 0.75rem;
}
.picimg {
  max-height: 14rem;
}
#fp {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: #ced4da !important;
  background-color: white !important;
}
</style>
